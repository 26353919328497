<template>
  <div style="transform: scale(0.75); margin-left: -15%">
    <div
      class="w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden"
    >
      <div class="header w-full h-30pc bg-slate-200">
        <img
          :src="
            formItem.enablePreChatBanner
              ? preChatBanner.fileUrl || preChatBanner.originalFileUrl
              : ''
          "
          alt=""
          class="w-full h-full object-cover"
        />
      </div>
      <div class="content text-center text-sm small:text-base medium:text-lg">
        <p class="text-center p-10">
          {{ formItem.greetingMessage }}
        </p>
        <p class="text-center p-2 text-red-500">
          {{ formItem.invalidUsernameAlertMessage }}
        </p>
        <input
          class="bg-slate-200 text-center px-4 py-2 w-80pc rounded-lg text-slate-800 focus:outline-none border-2 border-red-300"
          :placeholder="formItem.usernamePlaceholder"
          type="search"
          autocomplete="off"
          maxlength="100"
          value=""
        />
        <button
          disabled=""
          class="flex justify-center items-center w-80pc px-4 py-2 rounded-lg mx-auto mb-6 mt-2 text-white font-bold bg-gray-300"
          style="background-color: rgb(74, 184, 237)"
        >
          {{ formItem.submitButtonText || ' ' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreChatPreview',
  props: {
    formItem: Object,
    preChatBanner: Object,
  },
};
</script>

<style lang="scss">
@import 'PreChatPreview.scss';
</style>
